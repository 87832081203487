.footer {
    width: 100%;
    opacity: 0.5;
    background-color: #ccc;
    flex-basis: 50px;
    /*ie10*/
    -ms-flex-preferred-size: 50px;
    /*center align of links*/
    display: flex;
    align-items: center;
    /*vertical*/
    justify-content: space-around;
    /*or space-between*/
    /*ie10*/
    display: -ms-flexbox;
    -ms-flex-align: center;
    /*vertical*/
    -ms-flex-line-pack: distribute;
    /*or justify*/
    /*ie10 short content*/
    margin-top: auto;
    background: #0F3941;
}

.footer-link {
    text-transform: uppercase;
    color: #fff !important;
    font-style: bold;
    font-size: 0.9rem;
    text-decoration: none;
    &:hover {
        color: darken(#78A9A9, 6%) !important;
    }
    &.active {
        color: lighten(#78A9A9, 5%) !important;
    }
}
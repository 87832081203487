.researchImage {
    width: auto;
    max-height: 100%;
    overflow: hidden;
    filter: invert(1);
    img {
        margin: auto;
        display: block;
        object-fit: cover;
        object-position: center;
    }
}

.research-description {
    font-size: 1rem;
    color: #6f6f6f;
}
.header {
    width: 100%;
    background-color: #ccc;
    flex-basis: 50px;
    /*ie10*/
    -ms-flex-preferred-size: 50px;
    /*center align of links*/
    display: flex;
    align-items: center;
    /*vertical*/
    justify-content: center;
    /*or space-between*/
    /*ie10*/
    display: -ms-flexbox;
    -ms-flex-align: center;
    /*vertical*/
    -ms-flex-line-pack: distribute;
    /*or justify*/
    z-index: 10;
    position: sticky;
    top: 0;
    width: 100%;
    background: #0F3941;
}

.header a {
    /*ie10*/
    /*hack to trigger the layout for horizontal alignment distribute/justify*/
    display: inline-block;
}

.navbar-collapse {
    justify-content: center;
}

.navbar {
    z-index: 10;
    position: sticky;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    box-shadow: 0px 2px 6px rgba(43, 73, 111, 0.2);
}

.navbar-brand {
    font-weight: bold;
    letter-spacing: 2px;
    color: #4e4e4e;
    i {
        color: #78A9A9;
        font-size: 0.5rem;
    }
}

.nav-link {
    text-transform: uppercase;
    font-weight: 700;
    color: #4e4e4e !important;
    font-size: 1rem;
    &:hover {
        color: darken(#78A9A9, 6%) !important;
    }
    &.active {
        color: lighten(#78A9A9, 5%) !important;
    }
}

.nav-logo {
    width: 100%;
    max-height: 50px;
    overflow: hidden;
    img {
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
}
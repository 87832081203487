.card {
    box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
    border: none;
    .card-title {
        font-size: 0.9rem;
        font-weight: bolder;
        color: #4e4e4e;
        text-transform: uppercase;
    }
    .card-text {
        font-size: 1rem;
        color: #6f6f6f;
    }
    .card-img-top7 {
        max-width: 100%;
        max-height: 100%;
        -webkit-backface-visibility: hidden;
        -ms-transform: translateZ(0);
        /* IE 9 */
        -webkit-transform: translateZ(0);
        /* Chrome, Safari, Opera */
        transform: translateZ(0);
    }
}
.card {
    box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
    border: none;
    .card-title {
        font-size: 0.9rem;
        font-weight: bolder;
        color: #4e4e4e;
        text-transform: uppercase;
    }
    .card-text {
        font-size: 1rem;
        color: #6f6f6f;
    }
    .card-img-top {
        width: 200px;
        height: 200px;
        border-radius: 50%;
    }
}
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import './pages/header';
@import './pages/footer';
@import './pages/about';
@import './pages/team';
@import './pages/research';
@import './pages/validone';
@import './pages/fundnews';
html,
body {
    background: #0F3941;
    margin: 0;
    padding: 0;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.f-container {
    background: #0F3941;
    /* for shorter content */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /*ie10*/
    display: -ms-flexbox;
    -ms-flex-direction: column;
}

.main {
    top: 160px;
    background: #ccc;
    background-color: #ccc;
    flex-direction: column;
    align-items: stretch;
    flex: 1;
    /*ie10*/
    -ms-flex: 1 1 auto;
    /*needs both shrink and grow*/
}


/* fixed header and footer with scrollable main section */

.fixed-hf .main {
    overflow-y: auto;
}

.container {
    min-height: 100%;
    overflow: hidden;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: stretch;
    /* same as flex-grow: 1; */
}

.section-header {
    .section-title {
        z-index: 5;
        font-weight: bold;
        color: #4e4e4e;
        font-family: 'Raleway', sans-serif;
        position: relative;
        margin-bottom: 2rem;
        span {
            z-index: 5;
            color: #78A9A9;
        }
        &:after {
            z-index: 5;
            content: '';
            content: '';
            display: block;
            height: 3px;
            width: 70px;
            background: #78A9A9;
            position: relative;
            top: 20px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .section-subtitle {
        z-index: 5;
        max-width: 100%;
        color: #6f6f6f;
        font-size: 0.9rem;
    }
}

.text-bottom {
    width: auto;
    text-align: center;
}

button {
    padding: 5px 50px;
    border-radius: 5px;
    border-color: #fff;
    color: #fff;
    background-color: #0F3941;
}

.extrasmall {
    font-size: 0.7rem;
}

.cookieContainer {
    z-index: 5;
    border-radius: 4px;
    background-color: #212121;
    position: fixed;
    height: 11.4em;
    width: 24em;
    right: 8px;
    padding: 19px 30px;
    margin-bottom: 13px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}

.cookieHeader {
    color: rgba(189, 189, 189, 1);
    letter-spacing: 0.08em;
    font-size: 1.2em;
}

.cookieBtn {
    letter-spacing: 0.08em;
    padding: 5px 26px;
    background-color: #78A9A9;
    font-size: 0.9em;
    margin-top: 10px;
}

.cookieP {
    font-size: 0.9em;
    color: rgba(189, 189, 189, 1);
    font-weight: 400;
}

a.cookiePP {
    color: #78A9A9;
}

a.cookiePP:hover {
    color: #FFF;
}
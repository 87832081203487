.aboutImage {
    max-height: 100%;
    width: 100%; /* Change width to 100% */
    height: auto;
    overflow: hidden;
    filter: invert(1);
    flex: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutImage img {
    opacity: 0.7;
    max-width: 30%;
    max-height: 100%;
    width: auto;
    height: auto;
    object-fit: contain;
    display: block;
    margin: 0 auto; /* Add margin to horizontally center the image */
}

.about-description {
    opacity: 0.7;
    font-size: 0.9rem;
    color: #ffffff;
    background: #0F3941;
}
.performance {
    font-size: 0.9rem;
    color: #000;
    font-weight: bold;
    text-align: right;
}

.oppertunity {
    font-size: 0.9rem;
    color: #000;
    font-weight: bold;
    text-align: left;
}

.divider {
    /* minor cosmetics */
    display: table;
    font-size: 1rem;
    text-align: center;
    width: 100%;
    /* divider width */
    margin: 40px auto;
    /* spacing above/below */
}

.divider span {
    display: table-cell;
    position: relative;
}

.divider span:first-child,
.divider span:last-child {
    width: 50%;
    top: 13px;
    /* adjust vertical align */
    -moz-background-size: 100% 2px;
    /* line width */
    background-size: 100% 2px;
    /* line width */
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;
}

.divider span:first-child {
    /* color changes in here */
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(transparent), to(#000));
    background-image: -webkit-linear-gradient(180deg, transparent, #000);
    background-image: -moz-linear-gradient(180deg, transparent, #000);
    background-image: -o-linear-gradient(180deg, transparent, #000);
    background-image: linear-gradient(90deg, transparent, #000);
}

.divider span:nth-child(2) {
    color: #4e4e4e;
    padding: 0px 5px;
    width: auto;
    white-space: nowrap;
}

.divider span:last-child {
    /* color changes in here */
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#000), to(transparent));
    background-image: -webkit-linear-gradient(180deg, #000, transparent);
    background-image: -moz-linear-gradient(180deg, #000, transparent);
    background-image: -o-linear-gradient(180deg, #000, transparent);
    background-image: linear-gradient(90deg, #000, transparent);
}